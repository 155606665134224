<template>
  <v-container fluid class="d-flex flex-column pa-0 ma-0 container_ white">
    <div class="pa-5 justify-space-between header_">
      <div @click="scaricaIstruzioni()">
        <v-icon left color="primary">mdi-information</v-icon>
        Istruzioni d'uso
      </div>
      <div style="cursor: pointer" @click="disconnettiDialog = true">
        <v-icon left color="primary">mdi-account-arrow-left</v-icon>
        Disconnetti
      </div>
    </div>

    <div
      class="d-flex align-center justify-center"
      style="
        height: calc(100% - 50px);
        max-height: calc(100% - 100px);
        overflow: hidden;
      "
    >
      <div
        style="max-width: 1000px; width: 100%; max-height: 100%; overflow: auto"
      >
        <v-row class="ma-0 pa-0" style="min-height: fit-content">
          <v-col
            :cols="struttura.industria_40 ? 6 : 12"
            class="pa-3"
            v-for="navButton in navButtons"
            :key="navButton.link"
            v-show="
              !(aggiornamento || controllo) &&
              (!navButton.industria_40 || struttura.industria_40)
            "
            :class="{
              'col-4_0-1': navButton.is4_0_1,
              'col-4_0-2': navButton.is4_0_2
            }"
          >
            <v-card
              @click="$router.push(navButton.link)"
              style="border-radius: 30px; overflow: hidden"
              width="100%"
              :disabled="
                navButton.disableOnUpdate && (aggiornamento || controllo)
              "
            >
              <v-img :src="navButton.image" height="200px"></v-img>
              <v-card-title
                style="user-select: none"
                class="primary bianco d-flex justify-center"
              >
                {{ navButton.title }}
              </v-card-title>
            </v-card>
          </v-col>
          <v-col v-if="aggiornamento || controllo" cols="12">
            <v-card
              disabled
              class="megafont bottoni"
              style="border-radius: 30px"
            >
              <div
                class="d-flex flex-column align-center justify-center px-6"
                style="height: 436px; width: 100%"
              >
                <v-progress-circular
                  width="3"
                  size="50"
                  color="warning"
                  indeterminate
                  v-if="controllo"
                />
                <v-progress-linear
                  v-if="aggiornamento"
                  height="10"
                  :value="stato"
                  striped
                  color="warning"
                />
                <v-card-text class="text-center pb-0">
                  {{ descrizione }}
                </v-card-text>
              </div>
              <v-card-title class="warning bianco d-flex justify-center">
                <span v-if="controllo">
                  Sto verificando la disponibilità di aggiornamenti...
                </span>
                <span v-if="aggiornamento">Aggiornamento in corso...</span>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog max-width="300" v-model="disconnettiDialog">
      <v-card>
        <v-card-title class="headline">Disconnetti</v-card-title>
        <v-card-subtitle>Sei sicuro di volerti disconnettere?</v-card-subtitle>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn text @click="disconnettiDialog = false">Annulla</v-btn>
          <v-btn color="error" @click="disconnetti()">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { auth, storage } from "../plugins/firebase";
import { aggiornaApplicazione } from "../functions/aggiornaApplicazione";

export default {
  data() {
    return {
      disconnettiDialog: false,
      navButtons: [
        {
          title: "FORMAZIONE",
          image: require("@/assets/formazione.jpeg"),
          link: "/formazione"
          //visibleOnUpdate: false
        },
        {
          title: "SELEZIONE INTERVENTO",
          image: require("@/assets/intervento.jpeg"),
          link: "/interventi",
          disableOnUpdate: true
          //visibleOnUpdate: false
        },
        {
          title: "OPERATORI",
          image: require("@/assets/operatori.jpeg"),
          link: "/operatori",
          //visibleOnUpdate: false,
          is4_0_1: true,
          industria_40: true
        },
        {
          title: "SELEZIONE PAZIENTE",
          image: require("@/assets/paziente.jpeg"),
          link: "/pazienti",
          disableOnUpdate: true,
          //visibleOnUpdate: false,
          is4_0_2: true,
          industria_40: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      controllo: "aggiornamento/controllo",
      aggiornamento: "aggiornamento/aggiornamento",
      descrizione: "aggiornamento/descrizione",
      stato: "aggiornamento/stato",
      errore: "aggiornamento/errore",
      struttura: "struttura/struttura"
    }),

    showed(visible) {
      return function () {
        if (this.aggiornamento || this.controllo) {
          // console.log("visible: ", visible);
          return visible;
        } else {
          return true;
        }
      };
    }
  },
  methods: {
    aggiornaApplicazione,
    async scaricaIstruzioni() {
      const url = await storage
        .ref()
        .child("L327DT23005-DT-GPS-MED_2.0.0.pdf")
        .getDownloadURL();
      window.open(url);
    },
    disconnetti() {
      this.disconnettiDialog = false;
      auth.signOut();
    }
  }
};
</script>

<style>
.col-4_0-1 {
  background-color: #e6e6e6;
  border-radius: 30px 0 0 30px;
}
.col-4_0-2 {
  background-color: #e6e6e6;
  border-radius: 0 30px 30px 0;
}
.centra {
  display: flex;
  justify-content: center;
}
.megafont {
  font-size: 150% !important;
}
.bottoni {
  border-radius: 30px !important;
  overflow: hidden;
}
.offerte {
  position: absolute;
  right: -50px;
  top: 26px;
  transform: rotate(45deg);
  clip-path: polygon(25% 0, 0 90%, 0 96%, 100% 96%, 100% 90%, 76% 0);
  text-align: center;
  padding-top: 11px;
  padding-bottom: 10px;
  width: 207px;
  display: flex;
  justify-content: center;
  color: white;
  border-style: none;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
}
.figo {
  user-select: none;

  color: white;

  border-radius: 20px;
  padding: 10px;
  transition: 1s;
}
.figo:hover {
  box-shadow: 0px 10px 20px 0px #285aaa;
  background-color: #3371da;
}

.header_ {
  height: 60px;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.container_ {
  height: calc(100vh - 50px);
  overflow: hidden;
  max-height: calc(100vh - 50px);
}
</style>
